import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheck = makeShortcode("IconCheck");
const IconError = makeShortcode("IconError");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "multi-page-forms",
      "style": {
        "position": "relative"
      }
    }}>{`Multi-page forms`}<a parentName="h1" {...{
        "href": "#multi-page-forms",
        "aria-label": "multi page forms permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Filling a long form can be a daunting task for the user. One way to make a long form seem less like a chore is by
  dividing the form into separate steps that are shown one page at a time.
    </LeadParagraph>
    <h2 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h2" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`The goal of dividing a form into multiple pages (or steps) is to show less information on one page. Potentially, this has multiple benefits:`}</p>
    <ul>
      <li parentName="ul">{`Form steps will be shorter and require less cognitive effort to fill.`}</li>
      <li parentName="ul">{`Individual steps will be less overwhelming - encouraging the user to fill out the whole form.`}</li>
      <li parentName="ul">{`Each step will contain fewer form elements. This makes it easier to design forms that work well on different screen sizes.`}</li>
      <li parentName="ul">{`Users make fewer errors and errors will be easier to correct.`}</li>
    </ul>
    <p>{`It is also important to note that multi-page forms do not come without their downsides. The main downside is that they increase the interaction cost of filling out the form. This means the user is required to do more clicking and scrolling to fill the whole form. Therefore, it is always important to carefully consider when the multi-page approach is beneficial and when it is not. The following gives you some rules of thumb to decide whether the multi-page forms are needed in your project.`}</p>
    <h3 {...{
      "id": "when-should-you-use-multi-page-forms",
      "style": {
        "position": "relative"
      }
    }}>{`When should you use multi-page forms?`}<a parentName="h3" {...{
        "href": "#when-should-you-use-multi-page-forms",
        "aria-label": "when should you use multi page forms permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`When the form can be divided into distinct themes. For example, "`}<em parentName="li">{`Contact information`}</em>{`" -> "`}<em parentName="li">{`Application`}</em>{`" -> "`}<em parentName="li">{`Payment`}</em>{`" -> "`}<em parentName="li">{`Review and send`}</em>{`".`}</li>
      <li parentName="ul">{`When the form is very long.`}
        <ul parentName="li">
          <li parentName="ul">{`What is considered a high number of form elements depends on the context. Usually, the number can be considered high if the user is required to scroll the view to see all form elements on a desktop-sized screen.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`When your users tend to do a lot of errors when filling out the form.`}</li>
    </ul>
    <h3 {...{
      "id": "when-should-you-not-use-multi-page-forms",
      "style": {
        "position": "relative"
      }
    }}>{`When should you not use multi-page forms?`}<a parentName="h3" {...{
        "href": "#when-should-you-not-use-multi-page-forms",
        "aria-label": "when should you not use multi page forms permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`When the number of form elements is low. Dividing a small number of inputs into multiple steps increases interaction the cost of completing the form.`}
        <ul parentName="li">
          <li parentName="ul">{`What is considered a low number of form elements depends on the context. Usually, the number is considered low if you can fit all fields on a single view without the need to scroll on a desktop-sized screen.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`When the user needs to compare or move information between multiple form steps.`}</li>
      <li parentName="ul">{`When the form steps have a lot of relations. For example, a choice in a later form step must not make an earlier step erroneous.`}</li>
      <li parentName="ul">{`When you do not know in which order the user wants to fill out the form. Multi-step forms are generally designed to be filled out in a specific order.`}
        <ul parentName="li">
          <li parentName="ul">{`Note! HDS multi-page forms support filling out the steps in random order. However, this takes away many benefits that multi-page forms are designed to achieve.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "elements-of-a-multi-page-form",
      "style": {
        "position": "relative"
      }
    }}>{`Elements of a multi-page form`}<a parentName="h2" {...{
        "href": "#elements-of-a-multi-page-form",
        "aria-label": "elements of a multi page form permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`HDS multi-page forms consist of three (3) elements:`}</p>
    <ol>
      <li parentName="ol">{`Form headings`}</li>
      <li parentName="ol">{`Form navigation`}</li>
      <li parentName="ol">{`Form area`}</li>
    </ol>
    <h3 {...{
      "id": "1-form-headings",
      "style": {
        "position": "relative"
      }
    }}>{`1. Form headings`}<a parentName="h3" {...{
        "href": "#1-form-headings",
        "aria-label": "1 form headings permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS multi-page forms feature two separate headings; the main heading and the step heading.`}</p>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`form main heading`}</strong>{` describes the whole form and it is located above the form navigation element.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`form step heading`}</strong>{` describes the currently active step and it is located below the form navigation element.`}</li>
    </ul>
    <Image src="/images/patterns/form/multi-page/multi-page-form-headings@2x.png" alt="Form headings example" style={{
      "maxWidth": "768px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>
        The step heading <em>must</em> include the number of the current step and the total number of steps.
      </strong>
    </li>
    <li>
      When the form page changes, <strong>move the browser focus to the step heading.</strong> If you use the step
      heading provided by the
      <InternalLink size="M" href="/components/stepper" mdxType="InternalLink">
        HDS Stepper component
      </InternalLink>
      , this will be done automatically for you.
    </li>
    <li>
      Customise heading levels if needed so that they are used semantically (<strong>h1</strong> before
      <strong> h2</strong> and so on).
    </li>
  </ul>
    </div>
    <h3 {...{
      "id": "2-form-navigation",
      "style": {
        "position": "relative"
      }
    }}>{`2. Form navigation`}<a parentName="h3" {...{
        "href": "#2-form-navigation",
        "aria-label": "2 form navigation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS form navigation has two (2) main goals. First, it provides the user a visual indicator of the form progress. Second, it allows the user to navigate between (available) form steps.`}</p>
    <p>{`The navigation is offered in Default and Small sizes. The small size omits the step text labels. However, the step circles will remain as interactable buttons.`}</p>
    <Image src="/images/patterns/form/multi-page/multi-page-form-navigation@2x.png" alt="Form navigation example" style={{
      "maxWidth": "768px"
    }} viewable mdxType="Image" />
    <h4 {...{
      "id": "form-step-states",
      "style": {
        "position": "relative"
      }
    }}>{`Form step states`}<a parentName="h4" {...{
        "href": "#form-step-states",
        "aria-label": "form step states permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS provides pre-defined states for form steps. The following table lists all allowed states and describes situations where they should be used.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Image`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/multi-page/state-available@2x.png" alt="Available form step" style={{
              "maxWidth": "32px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Available`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The step is available and the user can navigate to it. The step has not been filled yet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/multi-page/state-selected@2x.png" alt="Selected form step" style={{
              "maxWidth": "36px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Selected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The step is currently active but has not been submitted yet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/multi-page/state-filled@2x.png" alt="Filled form step" style={{
              "maxWidth": "32px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The step has been filled successfully.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/multi-page/state-disabled@2x.png" alt="Disabled form step" style={{
              "maxWidth": "32px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The step is not available yet due to previous step not filled yet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/multi-page/state-attention@2x.png" alt="Form step demanding attention" style={{
              "maxWidth": "32px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Needs attention`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The step demands user's attention. E.g. it has been changed by another user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/multi-page/state-paused@2x.png" alt="Paused form step" style={{
              "maxWidth": "32px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Paused`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The step progress has been paused. E.g. waiting for a review from officials.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Available form step states and their uses]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "3-form-area",
      "style": {
        "position": "relative"
      }
    }}>{`3. Form area`}<a parentName="h3" {...{
        "href": "#3-form-area",
        "aria-label": "3 form area permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Below form headings and the form navigation is the form area. The form elements of the current step will be placed here. A step description and instructions can also be given below the step heading. Form buttons will be located at the very bottom of the form area. "Next" and "Previous" buttons should at least be offered but buttons can contain other actions too such as saving the form to continue later.`}</p>
    <p>{`To learn more about how to construct form in the City of Helsinki services, please refer to `}<InternalLink href="/patterns/forms/form-building" mdxType="InternalLink">{`HDS Form pattern documentation.`}</InternalLink></p>
    <Image src="/images/patterns/form/multi-page/multi-page-form-form-area@2x.png" alt="Form area example" style={{
      "maxWidth": "768px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      If your form description includes important instructions, you <strong>must</strong> include them on the every step
      of the form. Do not expect the user to remember instructions from earlier pages.
    </li>
  </ul>
    </div>
    <h2 {...{
      "id": "handling-errors",
      "style": {
        "position": "relative"
      }
    }}>{`Handling errors`}<a parentName="h2" {...{
        "href": "#handling-errors",
        "aria-label": "handling errors permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`One of the main benefits of dividing the form into multiple steps is that the users will make fewer errors in general and the errors will be easier to correct. To read more about form validation please refer to `}<InternalLink href="/patterns/forms/form-validation" mdxType="InternalLink">{`HDS Form validation pattern documentation.`}</InternalLink></p>
    <p>{`To make the error handling as easy as possible for the user, please follow these guidelines:`}</p>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      Use one of the form validation patterns listed in
      <InternalLink href="/patterns/forms/form-validation" mdxType="InternalLink"> HDS Form validation documentation</InternalLink>
    </li>
    <li>
      <strong>Do validation separately for each step/page.</strong>
      <ul style={{
            "marginTop": "0"
          }}>
        <li>Always validate all inputs of the page when the user tries to leave the page.</li>
        <li>The main benefit of this is that the user only needs to worry about errors on the current page.</li>
      </ul>
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Do not let the user leave pages that have erroneous fields.</strong> Always validate input and guide the
      user to fix the errors before navigating to other steps.
    </li>
    <li>
      Avoid situations where choices in steps could cause errors in other steps. Steps should be completely independent.
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          If this is necessary, make sure the user is well informed that attention is needed in earlier steps. Use HDS
          <InternalLink href="/components/notification" mdxType="InternalLink"> Inline Notification</InternalLink> for this.
        </li>
        <li>
          If it is possible that there are errors in previous steps when the user reaches the end of the form, offer a
          clear summary page of all errors are provide links to relevant pages to fix the errors.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <h2 {...{
      "id": "end-of-the-form",
      "style": {
        "position": "relative"
      }
    }}>{`End of the form`}<a parentName="h2" {...{
        "href": "#end-of-the-form",
        "aria-label": "end of the form permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`When the user reaches the end of the form, it is recommended to offer a review page as the last step of the form. This allows the user to review the information they are about to submit and potentially fix errors in the data. The review step is especially important for longer forms.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      